export const DEFAULT_LOCALE = "en-GB";

/**
 * *_APP_NAME: These app names are the folder names in S3 bucket where corresponding translations are stored.
 * Also, the name of respective app's Translations repo https://github.com/Unibuddy/translations-{appName}
 */
export const WIDGET_APP_NAME = "widget";
export const AMBASSADOR_DASHBOARD_APP_NAME = "ambassador-dashboard";
export const DISCOVER_APP_NAME = "discover";
export const UCAS_APP_NAME = "ucas";
export const LIVE_EVENTS_APP_NAME = "live-events";
export const UNIVERSITY_DASHBOARD_APP_NAME = "university-dashboard";
export const POPCARD_APP_NAME = "popcard";

/**
 * *_PROJECT_NAME: These are project names in Crowdin system
 */
export const WIDGET_PROJECT_NAME = "ub-widget";
export const POPCARD_PROJECT_NAME = "ub-popcard";
export const AMBASSADOR_DASHBOARD_PROJECT_NAME = "ub-ambassador-dashboard";
