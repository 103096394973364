import React, {createContext} from "react";
import kebabCase from "lodash/kebabCase";

const colors = {
    blackPearl: "dark",
    stoneGrey: "dark",
    smartGrey: "dark",
    softGrey: "light",
    inkWater: "light",
    solitude: "light",
    deepSolitude: "light",
    endeavour: "dark",
    astralBlue: "dark",
    deepSky: "dark",
    dodgerBlue: "dark",
    redCloud: "dark",
    pikachu: "dark",
    freshLeaves: "dark",
    pureHit: "dark",
    bodyColour: "light",
    lightBodyColour: "light",
    errorColour: "dark",
    primaryColour: "dark",
};

const knownBackgroundColors = {};

// eslint-disable-next-line
for (const [colorName, value] of Object.entries(colors)) {
    knownBackgroundColors[colorName] = value;
    knownBackgroundColors[`var(--${kebabCase(colorName)})`] = value;
}

export const BackgroundContext = createContext({status: "light"});

const getStatus = background => {
    if (background in knownBackgroundColors) {
        return knownBackgroundColors[background];
    }
};

export default function BackgroundColorProvider({children, background}) {
    if (!background) return children;
    const value = {
        status: getStatus(background),
    };
    return <BackgroundContext.Provider value={value}>{children}</BackgroundContext.Provider>;
}
