import React, {createContext, useContext, useMemo, PropsWithChildren} from "react";
import {LiveEventField, UniversityField} from "../../../types";

export type LiveEventContextType = {
    university?: UniversityField | null;
    liveEvent?: LiveEventField | null;
};

const LiveEventContext = createContext<LiveEventContextType>({university: null, liveEvent: null});

type LiveEventProviderType = PropsWithChildren<{
    university?: UniversityField | null;
    liveEvent?: LiveEventField | null;
}>;

export const useLiveEvent = () => {
    return useContext(LiveEventContext);
};
/**
 * Provides a simple way to access the liveEvent and university
 * data across the app. To consume the data use the useLiveEvent hook.
 *
 * @export
 * @param {*} {university, liveEvent, children}
 * @returns
 */
export default function LiveEventProvider({
    university,
    liveEvent,
    children,
}: LiveEventProviderType) {
    const data = useMemo(() => ({university, liveEvent}), [university, liveEvent]);
    return <LiveEventContext.Provider value={data}>{children}</LiveEventContext.Provider>;
}
