import {useState} from "react";
import {useErrorReporting} from "@unibuddy/error-reporting";

/**
 * A custom hook for temporarily persisting variables using sessionStorage
 * @param key String name of the variable to be stored
 * @param initialValue Stringifiable value to be used to initialise the storage
 * @returns {*[]} storedValue: value of the variable, setValue: fn to update the storage value
 */
const useSessionStorage = (key: string, initialValue?: any) => {
    const {reportError} = useErrorReporting();

    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.sessionStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.log(error);
            reportError(error);
            return initialValue;
        }
    });

    /**
     * Function used to update the stored value
     * We allow passing the actual updated value as well as a function to update
     * the existing value here to keep the hook in line with how useState works
     * Ref: https://reactjs.org/docs/hooks-reference.html#functional-updates
     * @param valueUpdate
     */
    const setValue = (valueUpdate: any) => {
        try {
            const updatedValue =
                valueUpdate instanceof Function ? valueUpdate(storedValue) : valueUpdate;
            setStoredValue(updatedValue);
            if (updatedValue !== null && updatedValue !== undefined) {
                window.sessionStorage.setItem(key, JSON.stringify(updatedValue));
            } else {
                window.sessionStorage.removeItem(key);
            }
        } catch (error) {
            console.log(error);
            reportError(error);
        }
    };

    return [storedValue, setValue];
};

export default useSessionStorage;
