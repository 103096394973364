import React from "react";
import styled from "styled-components";

const StyledLink = styled.a`
    text-decoration: none;
    color: var(--dodger-blue);
    :hover {
        text-decoration: underline;
    }
`;

interface IExternalLinkProps {
    children: React.ReactNode;
    href: string;
    target?: string;
}

const ExternalLink = ({children, href, target = "_blank", ...props}: IExternalLinkProps) => {
    return (
        <StyledLink href={href} target={target} rel={"noopener noreferrer"} {...props}>
            {children}
        </StyledLink>
    );
};

export default ExternalLink;
