export default {
    liveEvents: {
        NAME: "Events",
        events: {
            messageSent: {
                name: "Message Sent",
            },
            messageLiked: {
                name: "Message Liked",
            },
            messageUnLiked: {
                name: "Message Un-Liked",
            },
            messagePinned: {
                name: "Message Pinned",
                requiredFields: ["accountRole", "channelId", "subProduct"],
            },
            messageUnpinned: {
                name: "Message Unpinned",
                requiredFields: ["accountRole", "channelId", "subProduct"],
            },
            pinnedMessagesTabClicked: {
                name: "Pinned Messages Tab Clicked",
                requiredFields: ["accountRole", "chatType", "sessionId", "virtualEventId"],
            },
            messageResolved: {
                name: "Message Resolved",
                requiredFields: ["accountRole", "sessionId", "subProduct", "universitySlug"],
            },
            messageUnresolved: {
                name: "Message Unresolved",
                requiredFields: ["accountRole", "sessionId", "subProduct", "universitySlug"],
            },
            streamStartedByHost: {
                name: "Stream Started By Host",
                requiredFields: [
                    "streamId",
                    "hostNumber",
                    "subProduct",
                    "recorded",
                    "accountRole",
                    "universitySlug",
                ],
            },
            screenSharingStartedByHost: {
                name: "Screen Sharing Started Hy Host",
                requiredFields: ["streamId", "hostNumber", "subProduct"],
            },
            sessionSavedToAgenda: {
                name: "Session Saved To Agenda",
                requiredFields: ["sessionId", "virtualEventId"],
            },
            sessionRemovedFromAgenda: {
                name: "Session Removed From Agenda",
                requiredFields: ["sessionId", "virtualEventId"],
            },
            sessionPageViewed: {
                name: "Session Page Viewed",
                requiredFields: ["sessionId", "subProduct"],
            },
            chatRoomPageViewed: {
                name: "Chat Room Page Viewed",
                requiredFields: ["chatRoomId", "subProduct"],
            },
            eventsPanelOpened: {
                name: "Events Panel Opened",
                requiredFields: ["accountRole", "channelId"],
            },
            eventsPanelSectionOpened: {
                name: "Events Panel Section Opened",
                requiredFields: ["accountRole", "channelId", "section"],
            },
            recordingToggled: {
                name: "Recording Toggled",
                requiredFields: [
                    "toggledTo",
                    "accountRole",
                    "universitySlug",
                    "eventId",
                    "sessionId",
                    "subProduct",
                ],
            },
            recordingDownloaded: {
                name: "Recording Downloaded",
                requiredFields: [
                    "fileId",
                    "eventId",
                    "sessionId",
                    "accountRole",
                    "universitySlug",
                    "subProduct",
                ],
            },
            recordingDeleted: {
                name: "Recording Deleted",
                requiredFields: [
                    "fileId",
                    "eventId",
                    "sessionId",
                    "accountRole",
                    "universitySlug",
                    "subProduct",
                ],
            },
            videoRecordingFailed: {
                name: "Video Recording Failed",
                requiredFields: ["sessionId", "eventId", "subProduct"],
            },
            userBlockedFromUniversity: {
                name: "User Blocked From University",
                requiredFields: [
                    "accountRole",
                    "universitySlug",
                    "eventId",
                    "conversationId",
                    "chatType",
                    "subProduct",
                    "reason",
                    "userBlocked",
                ],
            },
            communityInviteBannerClicked: {
                name: "Community Banner - Clicked to learn more",
                requiredFields: ["universityId", "userId", "eventId"],
            },
            communityInviteBannerClosed: {
                name: "Community Banner - Dismissed",
                requiredFields: ["universityId", "userId", "eventId"],
            },
        },
    },
    engagementPortal: {
        events: {
            liveEventClicked: {
                name: "Live Event Clicked",
                requiredFields: ["eventId"],
            },
            externalContentClicked: {
                name: "External Content Clicked",
                requiredFields: ["eventId"],
            },
        },
    },
    openDay: {
        events: {
            liveEventClicked: {
                name: "Live Event Clicked",
                requiredFields: ["eventId"],
            },
            externalContentClicked: {
                name: "External Content Clicked",
                requiredFields: ["eventId"],
            },
            openDayClicked: {
                name: "Open Day Clicked",
                requiredFields: ["openDayId", "name"],
            },
        },
    },
};
