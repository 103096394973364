import React, {Suspense, useEffect} from "react";
import {Switch, Route, Redirect, useLocation, useParams} from "react-router-dom";
import {Box, DesignSystemProvider, VisuallyHidden} from "@unibuddy/patron";
import {RelativeRouter} from "@unibuddy/react-relative-router";
import {ErrorBoundary} from "@unibuddy/error-reporting";

import {productSpec} from "@unibuddy/machop/AnalyticsNew/constants/productSpecification";
import useAnalytics from "@unibuddy/machop/AnalyticsNew/hooks/useAnalytics";
import {
    EventPageLayout,
    ChatPageLayout,
} from "./Layout/components/VirtualEventLayout/VirtualEventLayout";

import VirtualEventProvider from "./core/components/VirtualEventProvider/VirtualEventProvider";
import AlertProvider from "./Notifications/AlertProvider";
import Error404 from "./Errors/Error404";
import Error403 from "./Errors/Error403";
import ProtectedRoute from "./AuthV2/components/ProtectedRoute/ProtectedRoute";
import {isValidSessionUser} from "./AuthV2/components/ProtectedRoute/rules";
import {virtualEventsTheme} from "./Utils/theme/theme";
import useUniversity from "./core/hooks/useUniversity";
import {UniversityField} from "./types";
import useSessionStorage from "./Utils/hooks/useSessionStorage";
import {analyticsEnabled} from "./Utils/mixpanel";

const AuthRoutes = React.lazy(
    () => import(/* webpackChunkName: "authRoutes" */ "./AuthV2/AuthRoutes/AuthRoutes"),
);

const VirtualEventHomepage = React.lazy(
    () => import(/* webpackChunkName: "virtualEventHomepage" */ "./VirtualEventPages/Homepage"),
);

const VirtualEventSessionPage = React.lazy(
    () =>
        import(/* webpackChunkName: "virtualEventSessionPage" */ "./VirtualEventPages/SessionPage"),
);

const VirtualEventEventPagePage = React.lazy(
    () =>
        import(
            /* webpackChunkName: "virtualEventEventPagePage" */ "./VirtualEventPages/EventPagePage"
        ),
);

const ChatRoomPage = React.lazy(
    () => import(/* webpackChunkName: "chatRoomPage" */ "./VirtualEventPages/ChatRoomPage"),
);

const AgendaPage = React.lazy(
    () => import(/* webpackChunkName: "agendaPage" */ "./Agenda/AgendaPage"),
);

/*
 * Because we can't decide what prefix to use, I am extracting
 * it as a variable here so that it can be easily changed later
 */
export const PREFIX = "virtual";

const VirtualEventSubRoutes = ({university}: {university: UniversityField}) => {
    const [pageOpened, setPageOpened] = useSessionStorage(`product-opened-event-sent`, false);
    const {universitySlug} = useParams<{universitySlug: string}>();

    const {
        events: {productOpened},
    } = useAnalytics();

    useEffect(() => {
        if (!pageOpened && analyticsEnabled()) {
            productOpened({
                name: productSpec.virtualEvents.NAME,
                universitySlug,
            });
            setPageOpened(true);
        }
    }, [pageOpened, productOpened, setPageOpened, universitySlug]);

    return (
        <VirtualEventProvider university={university}>
            <Switch>
                <Route path={`/:universitySlug/${PREFIX}/:virtualEventId/404`}>
                    <EventPageLayout>
                        <Error404 />
                    </EventPageLayout>
                </Route>
                <Route path={`/:universitySlug/${PREFIX}/:virtualEventId/403`}>
                    <EventPageLayout>
                        <Error403 />
                    </EventPageLayout>
                </Route>
                <Route path={`/:universitySlug/${PREFIX}/:virtualEventId/agenda`}>
                    <EventPageLayout>
                        <AgendaPage />
                    </EventPageLayout>
                </Route>

                <ProtectedRoute
                    path={`/:universitySlug/${PREFIX}/:virtualEventId/s/:sessionId`}
                    isValidUser={(authState: any) => isValidSessionUser(authState, universitySlug)}
                >
                    <ChatPageLayout>
                        <VirtualEventSessionPage />
                    </ChatPageLayout>
                </ProtectedRoute>

                <Route path={`/:universitySlug/${PREFIX}/:virtualEventId/p/:eventPageId`}>
                    <EventPageLayout>
                        <VirtualEventEventPagePage />
                    </EventPageLayout>
                </Route>

                <ProtectedRoute
                    path={`/:universitySlug/${PREFIX}/:virtualEventId/c/:chatRoomId`}
                    isValidUser={(authState: any) => isValidSessionUser(authState, universitySlug)}
                >
                    <ChatPageLayout>
                        <ChatRoomPage />
                    </ChatPageLayout>
                </ProtectedRoute>

                <Route path={`/:universitySlug/${PREFIX}/:virtualEventId`}>
                    <EventPageLayout>
                        <VirtualEventHomepage />
                    </EventPageLayout>
                </Route>

                <Route>
                    <EventPageLayout>
                        <Error404 />
                    </EventPageLayout>
                </Route>
            </Switch>
        </VirtualEventProvider>
    );
};

const VirtualEventsRoutes = () => {
    const {setAnalyticsValue} = useAnalytics();
    const {pathname} = useLocation();

    /**
     * We're leveraging the analytics provider to ensure that there is always a
     * product global value (product: Live Events) on every event. This means that
     * anyone in Research / Product can make inferences about Essentials vs Premium
     * as we will in time ensure that subProduct routes are mapped
     */
    useEffect(() => {
        setAnalyticsValue("product", "Live Events");
    }, [setAnalyticsValue]);

    const {university, loading} = useUniversity();

    if (loading) return <VisuallyHidden>Loading...</VisuallyHidden>;

    if (!university) {
        return <Box padding="xxlarge">We cannot load this university at this time</Box>;
    }

    if (!university) {
        return <Redirect to={`/:universitySlug/${PREFIX}/404`} />;
    }

    return (
        <ErrorBoundary boundaryName="VirtualEvents">
            <Suspense fallback={null}>
                <DesignSystemProvider theme={virtualEventsTheme(university?.colour)}>
                    <AlertProvider>
                        <RelativeRouter>
                            <Switch>
                                <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

                                <Route
                                    path={`/:universitySlug/${PREFIX}/:virtualEventId/auth`}
                                    render={(props) => (
                                        <EventPageLayout>
                                            <AuthRoutes university={university} {...props} />
                                        </EventPageLayout>
                                    )}
                                />

                                <Route
                                    path={[
                                        `/:universitySlug/${PREFIX}/:virtualEventId`,
                                        `/:universitySlug/${PREFIX}`,
                                    ]}
                                >
                                    <VirtualEventSubRoutes university={university} />
                                </Route>
                            </Switch>
                        </RelativeRouter>
                    </AlertProvider>
                </DesignSystemProvider>
            </Suspense>
        </ErrorBoundary>
    );
};

export default VirtualEventsRoutes;
