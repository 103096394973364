import * as React from "react";

export default function AgendaIcon({color = "#404446", ...props}: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M7.383 19.418H6.32A4.32 4.32 0 012 15.098v-8.7a4.32 4.32 0 014.32-4.32h8.695a4.32 4.32 0 014.32 4.326v1.06M7.383.943V3.14M14.14.943V3.14M6.243 6.908h8.783"
                stroke={color}
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.066 16.8a6.45 6.45 0 11-12.9 0 6.45 6.45 0 0112.9 0z"
                stroke={color}
                strokeWidth={1.5}
            />
            <path
                d="M16.616 14.4v2.307l1.761 1.763"
                stroke={color}
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
