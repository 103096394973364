import React from "react";
import {connect} from "react-redux";
import get from "lodash/get";
import compose from "lodash/flowRight";
import {hot} from "react-hot-loader/root";
import {SocketProvider} from "@unibuddy/sockets";
import {
    ErrorReportingProvider,
    createSentryAdaptor,
    initSentry,
    ErrorBoundary,
} from "@unibuddy/error-reporting";
import * as Sentry from "@sentry/browser";
import UniversalAnalyticsProvider from "@unibuddy/machop/AnalyticsNew/AnalyticsProvider/UniversalAnalyticsProvider";
import {
    productSpec,
    sourceTracking,
} from "@unibuddy/machop/AnalyticsNew/constants/productSpecification";
import {createFeatureFlaggingProvider} from "ub-feature-flagging-react";
import {getParameterByName} from "@unibuddy/machop/Utils/UrlUtils";
import withAuth from "@unibuddy/machop/Auth/withAuth/withAuth";
import SourceTrackingProvider from "@unibuddy/machop/SourceTracking/SourceTrackingProvider/SourceTrackingProvider";
import LegacyDesignSystemProvider from "@unibuddy/machop/Experimental/Utils/components/DesignSystemProvider/DesignSystemProvider";
import initI18n from "@unibuddy/machop/Translations/i18n";
import {DEFAULT_LOCALE, LIVE_EVENTS_APP_NAME} from "@unibuddy/machop/Translations/constants";
import AlertProvider from "./Notifications/AlertProvider";
import analyticsProductSpecification from "./analyticsProductSpecification";
import AppRoutes from "./AppRoutes";
import "./App.css";

const FeatureFlaggingProvider = createFeatureFlaggingProvider(process.env.LAUNCHDARKLY_CLIENT_ID);
// Initialise i18next
initI18n(LIVE_EVENTS_APP_NAME, DEFAULT_LOCALE);

// Initialise Sentry
initSentry({
    Sentry,
    dsn: "https://e6ca1154083445c7b4457cf2e20fddec@sentry.io/2409456",
    env: process.env.ENV_NAME ? process.env.ENV_NAME.toUpperCase() : "DEV",
    release: `virtual-events-${process.env.CIRCLE_SHA1}`,
});

const errorReportingAdaptor = createSentryAdaptor(Sentry);

const sourceTrackingParams = {
    ub_medium: getParameterByName(
        window.location.href,
        sourceTracking.sourceTrackingParams.properties.UB_MEDIUM,
    )
        ? getParameterByName(
              window.location.href,
              sourceTracking.sourceTrackingParams.properties.UB_MEDIUM,
          )
        : "product",
    ub_source: getParameterByName(
        window.location.href,
        sourceTracking.sourceTrackingParams.properties.UB_SOURCE,
    )
        ? getParameterByName(
              window.location.href,
              sourceTracking.sourceTrackingParams.properties.UB_SOURCE,
          )
        : productSpec.liveEvents.NAME,
    ub_campaign: getParameterByName(
        window.location.href,
        sourceTracking.sourceTrackingParams.properties.UB_CAMPAIGN,
    ),
    ub_content: getParameterByName(
        window.location.href,
        sourceTracking.sourceTrackingParams.properties.UB_CONTENT,
    ),
};

const analyticsEvents = {
    ...analyticsProductSpecification.liveEvents.events,
    ...analyticsProductSpecification.engagementPortal.events,
    ...analyticsProductSpecification.openDay.events,
};

const pusherAppCluster = PUSHER_APP_CLUSTER;
const pusherAppKey = PUSHER_APP_KEY;
const pusherAuthUrl = PUSHER_AUTH_URL;

const AppComponent = ({authState, analyticsAdaptors}) => {
    return (
        <ErrorReportingProvider adaptor={errorReportingAdaptor}>
            <ErrorBoundary>
                <FeatureFlaggingProvider user={{id: "ub-virtual-events"}}>
                    <UniversalAnalyticsProvider
                        user={get(authState, "me.anyUser")}
                        adaptors={analyticsAdaptors}
                        productEvents={analyticsEvents}
                    >
                        <AlertProvider>
                            <SourceTrackingProvider params={sourceTrackingParams}>
                                <SocketProvider
                                    token={authState?.token}
                                    socketCredentials={{
                                        PUSHER_APP_CLUSTER: pusherAppCluster,
                                        PUSHER_APP_KEY: pusherAppKey,
                                        PUSHER_AUTH_URL: pusherAuthUrl,
                                    }}
                                >
                                    <LegacyDesignSystemProvider>
                                        <AppRoutes />
                                    </LegacyDesignSystemProvider>
                                </SocketProvider>
                            </SourceTrackingProvider>
                        </AlertProvider>
                    </UniversalAnalyticsProvider>
                </FeatureFlaggingProvider>
            </ErrorBoundary>
        </ErrorReportingProvider>
    );
};

const App = compose(connect(), withAuth)(AppComponent);

export default hot(App);
