import mixpanel from "mixpanel-browser";

const mixpanelAdaptor = {
    init() {},
    setUser(user) {
        mixpanel.people.set({
            id: user.id,
            accountRole: user.accountRole,
        });

        mixpanel.identify(user.id);
    },
    aliasVisitor(user) {
        mixpanel.alias(user.id);

        mixpanel.people.set({
            id: user.id,
            accountRole: user.accountRole,
        });
    },
    reset() {
        mixpanel.reset();
    },
    trackEvent(...event) {
        mixpanel.track(...event);
    },
};

export default mixpanelAdaptor;
