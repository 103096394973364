import {
    InMemoryCache,
    IntrospectionFragmentMatcher,
    defaultDataIdFromObject,
} from "apollo-cache-inmemory";
import introspectionQueryResultData from "../../fragmentTypes.json";

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
});

const createCustomApolloCache = () =>
    new InMemoryCache({
        fragmentMatcher,
        dataIdFromObject: (object) => {
            switch (object.__typename) {
                case "ReplyOutputField":
                    return `ReplyOutputField:${object.messageId}`;
                case "AttendeeAnalytics":
                    return `AttendeeAnalytics:${object.userId}`;
                case "ChatConversation":
                    return `ChatConversation:${object.id}`;
                case "SessionAttendeeAnalytics":
                    return `SessionAttendeeAnalytics:${object.liveSessionId}`;
                default:
                    return defaultDataIdFromObject(object);
            }
        },
    });

export default createCustomApolloCache;
