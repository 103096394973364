import React from "react";
import styled from "styled-components";
import {Box, Stack} from "@unibuddy/patron";
import ExternalLink from "../ExternalLink/ExternalLink";
import Image404 from "./404.png";

const Wrapper = styled.div`
    display: flex;
    height: 100%;
`;

const Container = styled.div`
    margin: auto;
    max-width: 700px;
    border-radius: 8px;
    text-align: center;

    @media screen and (min-width: 768px) {
        font-size: 18px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.09);
    }
`;

const Image = styled.img`
    width: 80%;
    height: auto;

    @media screen and (min-width: 768px) {
        width: 390px;
    }
`;

const Title = styled.h1`
    color: var(--deep-sky);
    font-size: 32px;
    font-weight: 700;
`;

const Instruction = styled.div`
    color: var(--stone-grey);
`;

const Link = styled(ExternalLink)`
    color: var(--deep-sky);
`;

export default function Error404() {
    return (
        <Wrapper>
            <Container>
                <Box paddingTop="medium" paddingX="xlarge" paddingBottom="xlarge">
                    <Stack space="xlarge">
                        <Image src={Image404} alt="person looking at screen showing 404 text" />
                        <Title>Page not found</Title>
                        <Stack space="small">
                            <Instruction>
                                We&apos;re sorry, the page you requested could not be found.
                            </Instruction>
                            <Instruction>
                                You can either return to the previous page, or contact our support
                                team
                            </Instruction>
                        </Stack>
                        <Link href="mailto:support@unibuddy.com">Contact Unibuddy</Link>
                    </Stack>
                </Box>
            </Container>
        </Wrapper>
    );
}
