import {routerReducer as routing} from "react-router-redux";
import authReducer from "@unibuddy/machop/Auth/reducers/authReducer";
import messengerReducer from "@unibuddy/machop/Chat/reducers/messengerReducer";

const rootReducer = {
    routing,
    authState: authReducer,
    messengerState: messengerReducer,
};

export default rootReducer;
