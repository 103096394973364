import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunk from "redux-thunk";
import {autoRehydrate} from "redux-persist";
import {routerReducer} from "react-router-redux";
import rootReducer from "./rootReducer";

export default function configureStore(preloadedState) {
    return createStore(
        combineReducers({
            ...rootReducer,
            routing: routerReducer,
        }),
        preloadedState,
        compose(
            applyMiddleware(thunk),
            autoRehydrate(),
        ),
    );
}
