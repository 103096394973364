import {useParams} from "react-router";
import useAuth from "@unibuddy/machop/Auth/hooks/useAuth";
import useUniversityFeature from "@unibuddy/machop/Feature/useUniversityFeature";

type Params = { universitySlug: string }

export default function useVerifiedUser() {
    const {universitySlug} = useParams<Params>();

    const variables = {
        universitySlug,
        feature: "enableEmailVerification"
    };

    const {isFeatureEnabled, loading, error} = useUniversityFeature(variables);
    const {isVerified, user} = useAuth();
    const isApplicant = user?.accountRole === "applicant";

    return {loading, error, isVerificationRequired: isApplicant && isFeatureEnabled && !isVerified};
}

