import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M0.815429 9.365C0.666615 9.36538 0.521043 9.32154 0.397187 9.23905C0.273331 9.15655 0.176775 9.03912 0.119775 8.90166C0.0627742 8.76419 0.047899 8.61289 0.0770366 8.46696C0.106174 8.32102 0.178011 8.18704 0.28343 8.082L3.29343 5.054L0.29343 2.075C0.221606 2.00604 0.164231 1.92347 0.124652 1.83211C0.0850727 1.74075 0.0640822 1.64242 0.0629041 1.54286C0.0617261 1.44329 0.0803839 1.34449 0.11779 1.25222C0.155196 1.15994 0.210601 1.07604 0.280774 1.0054C0.350947 0.934767 0.434482 0.87881 0.526508 0.840795C0.618534 0.802781 0.717208 0.783471 0.816775 0.783992C0.916342 0.784512 1.01481 0.804854 1.10643 0.843829C1.19805 0.882804 1.281 0.939632 1.35043 1.011L4.88643 4.518C4.95621 4.5875 5.01159 4.67009 5.04937 4.76104C5.08715 4.852 5.1066 4.94951 5.1066 5.048C5.1066 5.14649 5.08715 5.24401 5.04937 5.33496C5.01159 5.42591 4.95621 5.5085 4.88643 5.578L1.35043 9.143C1.28039 9.21358 1.19703 9.26955 1.10519 9.30765C1.01335 9.34576 0.914861 9.36526 0.815429 9.365V9.365Z"
                fill="#0171E0"
            />
        </svg>
    );
}

export default SvgComponent;
