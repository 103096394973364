import React, {FC} from "react";
import LogoutIcon from "react-icons/lib/io/log-out";
import compose from "lodash/flowRight";
import {connect} from "react-redux";
import {useApolloClient, useMutation} from "react-apollo";
import {Button, Tooltip, Hidden} from "@unibuddy/patron";
import useAuth from "@unibuddy/machop/Auth/hooks/useAuth";
import {clearAuth} from "@unibuddy/machop/Auth/actions/authActions";
import gql from "graphql-tag";
import {useIsBigScreen} from "../../../Utils/hooks/useIsBigScreen";
import {LogoutUserMutation} from "../../../types";

interface Props {
    dispatch: (arg0: any) => void;
}

export const logoutMutation = gql`
    mutation logoutUser {
        logout {
            success
        }
    }
`;

export const LogoutButton: FC<Props> = ({dispatch}) => {
    const {user} = useAuth();
    const {isBigScreen} = useIsBigScreen();
    const client = useApolloClient();
    const [logout, {loading}] = useMutation<LogoutUserMutation>(logoutMutation, {
        onCompleted: async (result) => {
            if (result.logout?.success) {
                await client.clearStore();
                dispatch(clearAuth());
            } else {
                throw new Error("Logout failed");
            }
        },
    });
    if (!user) return null;

    return (
        <Tooltip label="Logout">
            <Button
                aria-label="Logout"
                onClick={logout}
                clear
                size={isBigScreen ? "md" : "lg"}
                disabled={loading}
            >
                <LogoutIcon aria-hidden />
                <Hidden below="desktop">
                    {user.firstName} {user.lastName}
                </Hidden>
                <Hidden above="tablet">Log out</Hidden>
            </Button>
        </Tooltip>
    );
};

export default compose(connect())(LogoutButton);
