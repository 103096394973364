import {useMemo} from "react";
import {useLocation} from "react-router-dom";

interface ILocation {
    search?: string;
}

/**
 * @param param string value to access query param
 * @returns string
 */
export function useQueryParams(param: string): string | undefined | null {
    const location = useLocation<ILocation>();

    const queryString = location.search;
    /** we safeguard for any IE browser that doesn't implement this, even if we don't really care */

    const redirectUrl = useMemo(() => {
        const urlParams =
            typeof URLSearchParams !== "undefined" ? new URLSearchParams(queryString) : null;
        return urlParams?.get(param);
    }, [param, queryString]);

    return redirectUrl;
}
