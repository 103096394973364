import {useEffect} from "react";
import useAuth from "@unibuddy/machop/Auth/hooks/useAuth";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {useMutation} from "react-apollo";
import gql from "graphql-tag";
import {useParams} from "react-router";
import {useVirtualEvent} from "../../core/components/VirtualEventProvider/VirtualEventProvider";
import {AddChatRoomAttendeesMutation, AddChatRoomAttendeesMutationVariables} from "../../types";

export const chatRoomAttendeeAnalyticsMutation = gql`
    mutation addChatRoomAttendees(
        $chatRoomId: ObjectId!
        $userId: ObjectId!
        $accountRole: String!
    ) {
        addChatRoomAttendees(
            chatRoomAttendeeData: {
                chatRoomId: $chatRoomId
                userId: $userId
                accountRole: $accountRole
            }
        ) {
            id
            chatRoomId
            userId
            accountRole
            active
        }
    }
`;

export const chatRoomAttendeeAnalyticsFragment = gql`
    fragment ChatRoomAttendeeAnalytics on ChatRoomAttendeeAnalytics {
        id
        chatRoomId
        userId
        accountRole
        active
    }
`;

export default function useChatRoomAttendeeAnalytics() {
    const {reportError} = useErrorReporting();
    const {virtualEventId, chatRoomId} = useParams<{virtualEventId: string; chatRoomId: string}>();
    const {isLoggedIn, authState} = useAuth();
    const {isVirtualEventLive, hasVirtualEventEnded} = useVirtualEvent();

    const [addChatRoomAttendeeAnalytics] = useMutation<
        AddChatRoomAttendeesMutation,
        AddChatRoomAttendeesMutationVariables
    >(chatRoomAttendeeAnalyticsMutation);

    const user = authState?.me?.anyUser;

    useEffect(() => {
        if (
            !isVirtualEventLive ||
            hasVirtualEventEnded ||
            !virtualEventId ||
            !isLoggedIn ||
            !user?.id ||
            !chatRoomId
        ) {
            return;
        }

        async function updateAnalytics() {
            try {
                await addChatRoomAttendeeAnalytics({
                    variables: {
                        chatRoomId,
                        userId: user.id,
                        accountRole: user.accountRole,
                    },
                });
            } catch (e) {
                reportError(e);
            }
        }

        updateAnalytics();
    }, [
        user,
        isLoggedIn,
        virtualEventId,
        isVirtualEventLive,
        hasVirtualEventEnded,
        addChatRoomAttendeeAnalytics,
        chatRoomId,
        reportError,
    ]);
}
