import gql from "graphql-tag";

const applicantFragment = gql`
    fragment ApplicantFull on ApplicantField {
        id
        degreeLevel {
            id
            name
        }
        phoneNumber
        degreesInterested {
            id
            name
        }
        universitiesInterested {
            id
            name
            slug
        }
        applicantUniversities {
            university {
                id
                slug
                name
            }
            applied
            setupComplete
            hasAcceptedMarketing
            degreesInterested {
                id
                name
            }
        }
        preferences {
            newMessageNotifications
        }
        marketplaces {
            id
            slug
            userData {
                yearOfEntry
                custom
                id
                degreeCategories
                marketingConsentAccepted
                personalDataCollectionAccepted
            }
        }
        hasReadChatRules
    }
`;

export default applicantFragment;
