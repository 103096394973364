import React, {ReactNode, useLayoutEffect} from "react";
import styled from "styled-components";
import {Link, useLocation, useRouteMatch} from "react-router-dom";
import MenuIcon from "react-icons/lib/io/android-menu";
import {
    Box,
    Button,
    ButtonLink as DSButtonLink,
    Split,
    Inline,
    Hidden,
    View,
    Drawer,
    DrawerFooter,
    Stack,
} from "@unibuddy/patron";
import {useRelativeRouter} from "@unibuddy/react-relative-router";
import useAuth from "@unibuddy/machop/Auth/hooks/useAuth";

import AgendaIcon from "./Icons/AgendaIcon";
import VirtualEventLayoutProvider, {
    useVirtualEventDrawerContext,
} from "./VirtualEventLayoutProvider";
import {useQueryParams} from "../../../Utils/hooks/useQueryParams";
import LogoutButton from "../../../AuthV2/components/LogoutButton/LogoutButton";
import {useIsBigScreen} from "../../../Utils/hooks/useIsBigScreen";
import useAttendeeAnalytics from "../../../Analytics/hooks/useAttendeeAnalytics";
import ExternalLink from "../../../ExternalLink/ExternalLink";
import PoweredBy from "../../../PoweredBy/PoweredBy";
import RecordingFailedAlert from "../../../Notifications/RecordingFailedAlert/RecordingFailedAlert";
import useUniversity from "../../../core/hooks/useUniversity";
import useChatRoomAttendeeAnalytics from "../../../Analytics/hooks/useChatRoomAttendeeAnalytics";
import {ACCESSIBILITY_POLICY_URL, PRIVACY_POLICY_URL, T_AND_C_URL} from "../../../Utils/constants";

const HeaderContainer = styled.header`
    background-color: white;
    border-bottom: 3px solid ${({theme}) => theme?.colors?.universityColor};
    position: fixed;
    height: ${({theme}) => theme?.virtualEvents?.layout?.header?.height};
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
`;

const EventMain = styled.main`
    padding-top: ${({theme}) => theme?.virtualEvents?.layout?.header?.height};
    flex-grow: 1;

    /* We want to remove all of these inherited styles */
    svg {
        font-size: inherit;
        margin: 0;
    }
`;

const SessionMain = styled.main`
    padding-top: ${({theme}) => theme?.virtualEvents?.layout?.header?.height};
    background-color: ${({theme}) => theme?.colors?.faintGrey};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
    /* On devices with small height, the Video is too big so it needs to
    be a scroll-able container or the live chat won't fit */
    @media (max-height: 600px) {
        min-height: unset;
    }
`;

const FooterContainer = styled.footer`
    height: ${({theme}) => theme?.virtualEvents?.layout?.footer?.height};
    background-color: #f3f5f5;
`;

const UniversityLogo = styled.img`
    max-height: 50px;
`;

const LogoLink = styled(Link)`
    display: flex;
`;

const ResponsiveView = styled(View)`
    height: 100%;
    @media (min-width: 1024px) {
        height: ${({theme}) => `calc(100% + ${theme?.virtualEvents?.layout?.footer?.height})`};
    }
`;

const FooterLink = styled(ExternalLink)`
    text-decoration: none;
    color: var(--stone-grey);

    &:hover {
        text-decoration: underline;
        color: var(--smart-grey);
    }
`;

export function VirtualEventContainer({children}: {children: ReactNode}): JSX.Element {
    return (
        <View flexGrow="1" w="100%" maxW="1440px" margin="auto">
            {children}
        </View>
    );
}

const ButtonLink = styled(DSButtonLink)`
    background-color: ${({$active, size}) => ($active && size === "md" ? "#DFEDF7" : undefined)};
    color: ${({$active}) => ($active ? "#0171E0" : undefined)};
    font-weight: 600;

    &:hover:not([disabled]) {
        color: ${({theme, clear}) => {
            return clear ? `${theme.colors.universityColor}` : "#fff";
        }};
    }
`;

const AdminButton = () => {
    const {user, isLoggedIn} = useAuth();
    const {isBigScreen} = useIsBigScreen();

    if (!isLoggedIn || user?.accountRole !== "university") return null;

    return (
        <ButtonLink
            to={`${process.env.ABRA_URL}/events/microsite/admin`}
            external
            rel="nofollow noreferrer noopener"
            clear
            target="_blank"
            size={isBigScreen ? "md" : "lg"}
        >
            Admin
        </ButtonLink>
    );
};

const AgendaButton = ({showText = false, ariaLabel = "agenda button"}) => {
    const match = useRouteMatch();
    const {url} = useRelativeRouter();
    const {isBigScreen} = useIsBigScreen();
    const isOnAgendaUrl = match.url.endsWith("/agenda");

    return (
        <ButtonLink
            aria-label={ariaLabel}
            to={`${url}/agenda`}
            clear
            iconOnly={!isBigScreen && !showText}
            size={isBigScreen ? "md" : "lg"}
            $active={isOnAgendaUrl}
        >
            <AgendaIcon {...(isOnAgendaUrl ? {color: "#0171E0"} : "")} />{" "}
            {showText || isBigScreen ? "Agenda" : null}
        </ButtonLink>
    );
};

const footerLinks = [
    <FooterLink key="privacyPolicy" href={PRIVACY_POLICY_URL}>
        Privacy Policy
    </FooterLink>,
    <FooterLink key="TermsOfUse" href={T_AND_C_URL}>
        Terms of Use
    </FooterLink>,
    <FooterLink key="A11yPolicy" href={ACCESSIBILITY_POLICY_URL}>
        Accessibility Policy
    </FooterLink>,
    <FooterLink
        key="CookieSettings"
        as={Button}
        link
        onClick={() => window.OneTrust.ToggleInfoDisplay()}
    >
        Cookie Settings
    </FooterLink>,
];

const DrawerMenu = () => {
    const {isOpen, toggleDrawerOpen} = useVirtualEventDrawerContext();
    const {url} = useRelativeRouter();
    const location = useLocation();
    const redirectPath = useQueryParams("redirectPath");
    const {isLoggedIn} = useAuth();
    const currentPathName = redirectPath || location.pathname;

    return (
        <Drawer isOpen={isOpen} onDismiss={toggleDrawerOpen} size="small" position="fixed">
            <View minH="100%">
                <View flex="1" padding="medium">
                    <View flex="1" paddingY="large">
                        {isLoggedIn ? (
                            <Stack space="medium">
                                <AdminButton />
                                <AgendaButton showText ariaLabel="drawer agenda button" />
                                <LogoutButton />
                            </Stack>
                        ) : (
                            <Stack space="medium">
                                <AgendaButton showText ariaLabel="drawer agenda button" />
                                <ButtonLink
                                    aria-label={"Drawer Log in Button"}
                                    to={`${url}/auth/login?redirectPath=${currentPathName}`}
                                    clear
                                    color="primary"
                                >
                                    Log in
                                </ButtonLink>
                                <ButtonLink
                                    aria-label={"Drawer Sign up Button"}
                                    to={`${url}/auth/sign-up-step-one?redirectPath=${currentPathName}`}
                                    clear
                                >
                                    Sign up
                                </ButtonLink>
                            </Stack>
                        )}
                    </View>
                    <Stack space="medium">{footerLinks}</Stack>
                </View>
                <DrawerFooter>
                    <Box
                        minH={60}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        padding="medium"
                    >
                        <PoweredBy />
                    </Box>
                </DrawerFooter>
            </View>
        </Drawer>
    );
};

export const Header = () => {
    const {isLoggedIn} = useAuth();
    const {university} = useUniversity();
    const {url} = useRelativeRouter();
    const location = useLocation();
    const redirectPath = useQueryParams("redirectPath");
    const {isBigScreen} = useIsBigScreen();
    const {toggleDrawerOpen} = useVirtualEventDrawerContext();

    const AuthButton = () => {
        const isAuthRoute = location.pathname.includes("/auth/");
        const currentPathName = redirectPath || location.pathname;
        if (isAuthRoute) return null;
        return isLoggedIn ? (
            <LogoutButton />
        ) : (
            <Inline space={["xxsmall", "medium"]}>
                <ButtonLink
                    to={`${url}/auth/login?redirectPath=${currentPathName}`}
                    clear
                    color="primary"
                >
                    Log in
                </ButtonLink>
                <ButtonLink
                    to={`${url}/auth/sign-up-step-one?redirectPath=${currentPathName}`}
                    color="primary"
                >
                    Sign up
                </ButtonLink>
            </Inline>
        );
    };

    return (
        <HeaderContainer>
            <VirtualEventContainer>
                <Box
                    paddingY="xsmall"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginX="xsmall"
                >
                    <LogoLink to={url}>
                        <UniversityLogo
                            src={university ? university?.logo! : ""}
                            alt={`${university ? university?.name : "institution"} logo`}
                        />
                    </LogoLink>
                    <Inline verticalAlign="center" wrap="nowrap" space={["none", "xsmall"]}>
                        <AgendaButton />
                        {isBigScreen ? <AdminButton /> : null}
                        {isBigScreen ? (
                            <AuthButton />
                        ) : (
                            <Button
                                aria-label={"Open Drawer Button"}
                                onClick={toggleDrawerOpen}
                                size="lg"
                                iconOnly
                                clear
                            >
                                <MenuIcon />
                            </Button>
                        )}
                    </Inline>
                </Box>
            </VirtualEventContainer>
        </HeaderContainer>
    );
};

const Footer = () => (
    <FooterContainer>
        <VirtualEventContainer>
            <Box paddingY="small" marginX="medium">
                <Hidden below="desktop">
                    <Split verticalAlign="center">
                        <Inline space="xlarge" verticalAlign="center">
                            {footerLinks}
                        </Inline>
                        <FooterLink href="https://unibuddy.com">
                            <PoweredBy />
                        </FooterLink>
                    </Split>
                </Hidden>
                <Hidden above="tablet">
                    <FooterLink href="https://unibuddy.com">
                        <PoweredBy />
                    </FooterLink>
                </Hidden>
            </Box>
        </VirtualEventContainer>
    </FooterContainer>
);

export function BaseEventLayout({children}: {children: ReactNode}) {
    useAttendeeAnalytics();
    return <VirtualEventLayoutProvider>{children}</VirtualEventLayoutProvider>;
}
export function EventPageLayout({children}: {children: ReactNode}) {
    const {isBigScreen} = useIsBigScreen();
    const location = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <BaseEventLayout>
            <View h="100%">
                <Header />
                <EventMain>
                    <RecordingFailedAlert />
                    {children}
                </EventMain>

                <Footer />
                {isBigScreen ? null : <DrawerMenu />}
            </View>
        </BaseEventLayout>
    );
}

export const ChatPageLayout = ({children}: {children: ReactNode}) => {
    useChatRoomAttendeeAnalytics();
    const {isBigScreen} = useIsBigScreen();
    const location = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <BaseEventLayout>
            <ResponsiveView>
                <Header />
                <SessionMain>
                    <RecordingFailedAlert />
                    <VirtualEventContainer>{children}</VirtualEventContainer>
                </SessionMain>
                {isBigScreen ? <Footer /> : <DrawerMenu />}
            </ResponsiveView>
        </BaseEventLayout>
    );
};
