import React, {Suspense, useState} from "react";
import get from "lodash/get";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import {DesignSystemProvider} from "@unibuddy/patron";
import {getParameterByName} from "@unibuddy/machop/Utils/UrlUtils";
import DevTools from "./DevTools/DevTools";
import {virtualEventsTheme} from "./Utils/theme/theme";

const LandingPageRoutes = React.lazy(() =>
    import(
        /* webpackChunkName: "landingPageRoutes" */ "./LandingPage/LandingPageRoutes/LandingPageRoutes"
    ),
);

const OpenDaysRoutes = React.lazy(() =>
    import(/* webpackChunkName: "openDaysRoutes" */ "./OpenDays/OpenDaysRoutes/OpenDaysRoutes"),
);

const LiveEventRoutes = React.lazy(() =>
    import(/* webpackChunkName: "liveEventsRoutes" */ "./LiveEventRoutes"),
);

const Error404 = React.lazy(() =>
    import(/* webpackChunkName: "error404Page" */ "./Errors/Error404"),
);

const AppRoutes = () => {
    const match = useRouteMatch("/:universitySlug");
    const universitySlug = get(match, "params.universitySlug");
    const [openDayId] = useState(
        () => getParameterByName(window.location.href, "ub_campaign") || "",
    );

    return (
        <Suspense fallback={null}>
            <DesignSystemProvider theme={virtualEventsTheme()}>
                <Switch>
                    <Route
                        path="/:universitySlug/landing"
                        render={(props) => <LandingPageRoutes {...props} />}
                    />
                    <Route
                        path="/:universitySlug/open/:id"
                        render={(props) => <OpenDaysRoutes {...props} />}
                    />
                    <Route
                        path="/:universitySlug/:liveEventSlug"
                        render={(props) => (
                            <LiveEventRoutes
                                {...props}
                                universitySlug={universitySlug}
                                openDayId={openDayId}
                            />
                        )}
                    />
                    <Route path="/devtools" component={DevTools} />
                    <Route render={() => <Error404 />} />
                </Switch>
            </DesignSystemProvider>
        </Suspense>
    );
};

export default AppRoutes;
