import {Event, ClientEvent, Channel, PresenceChannel} from "@unibuddy/sockets";

const conversationChannel = new Channel("conversation", {private: true});
export const conversationUpdatedEventSendMessage = new Event("send-message", conversationChannel);
export const conversationUpdatedEventLikeMessage = new Event("like-message", conversationChannel);
export const conversationUpdatedEventUnlikeMessage = new Event(
    "unlike-message",
    conversationChannel,
);
export const conversationUpdatedEventDeleteMessage = new Event(
    "delete-message",
    conversationChannel,
);
export const conversationUpdatedEventResolveMessage = new Event(
    "resolve-message",
    conversationChannel,
);
export const conversationUpdatedEventUnresolveMessage = new Event(
    "unresolve-message",
    conversationChannel,
);

export const conversationPinMessage = new Event("pin-message", conversationChannel);

export const conversationUnpinMessage = new Event("unpin-message", conversationChannel);

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const sessionPresenceChannel = new PresenceChannel("session");

export const chatRoomPresenceChannel = new PresenceChannel("chat-room");

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const videoChannel = new Channel("video", {private: true});

export const recordingFailedEvent = new ClientEvent("recording-failed", videoChannel);

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const chatChannel = new Channel("chat-channel", {private: true});

export const isTypingEvent = new ClientEvent("is-typing", chatChannel);
