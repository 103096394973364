import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="27"
            height="28"
            viewBox="0 0 27 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M13.3872 3.94006C15.2648 3.93984 17.1002 4.50269 18.6615 5.55745C20.2227 6.6122 21.4397 8.11147 22.1583 9.86566C22.877 11.6199 23.0651 13.5502 22.699 15.4125C22.3328 17.2749 21.4288 18.9856 20.1012 20.3283C18.7737 21.671 17.0822 22.5855 15.2407 22.956C13.3992 23.3265 11.4904 23.1364 9.75574 22.4099C8.02107 21.6833 6.5384 20.4528 5.49525 18.874C4.4521 17.2952 3.89532 15.4391 3.89532 13.5403C3.89827 10.9951 4.89936 8.55494 6.679 6.7552C8.45864 4.95545 10.8715 3.94304 13.3883 3.94006H13.3872ZM13.3883 2.25146C11.1805 2.25146 9.02232 2.91354 7.18663 4.15398C5.35093 5.39441 3.92018 7.15748 3.0753 9.22025C2.23042 11.283 2.00936 13.5528 2.44008 15.7426C2.87079 17.9325 3.93394 19.9439 5.49507 21.5227C7.0562 23.1015 9.0452 24.1766 11.2106 24.6122C13.3759 25.0478 15.6203 24.8242 17.6601 23.9698C19.6998 23.1154 21.4432 21.6685 22.6697 19.812C23.8963 17.9556 24.551 15.773 24.551 13.5403C24.551 12.0577 24.2622 10.5897 23.7012 9.21999C23.1401 7.85029 22.3178 6.60577 21.2811 5.55749C20.2444 4.50921 19.0137 3.6777 17.6593 3.11045C16.3048 2.5432 14.8532 2.25132 13.3872 2.25146H13.3883Z"
                fill="#363640"
            />
            <path
                d="M13.3876 16.2511C13.1662 16.2511 12.9538 16.1621 12.7973 16.0038C12.6407 15.8454 12.5527 15.6307 12.5527 15.4068V7.40393C12.5527 7.18001 12.6407 6.96526 12.7973 6.80692C12.9538 6.64858 13.1662 6.55963 13.3876 6.55963C13.609 6.55963 13.8214 6.64858 13.9779 6.80692C14.1345 6.96526 14.2225 7.18001 14.2225 7.40393V15.4068C14.2225 15.6307 14.1345 15.8454 13.9779 16.0038C13.8214 16.1621 13.609 16.2511 13.3876 16.2511Z"
                fill="#363640"
            />
            <path
                d="M13.3866 20.4624C14.0014 20.4624 14.4997 19.9584 14.4997 19.3367C14.4997 18.715 14.0014 18.211 13.3866 18.211C12.7718 18.211 12.2734 18.715 12.2734 19.3367C12.2734 19.9584 12.7718 20.4624 13.3866 20.4624Z"
                fill="#363640"
            />
        </svg>
    );
}

export default SvgComponent;
