
export const isValidSessionUser = (authState: any, universitySlug: string) => {
    const accountRole = authState.me.anyUser.accountRole;

    if (accountRole === "applicant") return true;
    if (accountRole === "admin") return false;
    const slug = authState.me[accountRole !== "university" ? accountRole : "universityUser"].university.slug;

    // The Users a valid user if they are using the same slug
    return universitySlug === slug;
};

export const isValidUniversityAdminUser = (authState: any, universitySlug: string) => {
    const user = authState.me.anyUser;
    if (!user) return false;
    if (user.accountRole !== "university") return false;
    return authState.me.universityUser.university.slug === universitySlug;
};
