import React, {
    createContext,
    useState,
    useContext,
    useEffect,
    useCallback,
    useMemo,
    cloneElement,
} from "react";
import {v4} from "uuid";
import noop from "lodash/noop";
import Stack from "@unibuddy/machop/Experimental/Layout/components/Stack/Stack";
import HiddenErrorBoundary from "@unibuddy/machop/General/components/HiddenErrorBoundary/HiddenErrorBoundary";
import styled from "styled-components";
import {useTransition, animated} from "react-spring";
import Toast from "../Toast/Toast";

const Context = createContext({
    addToast: noop,
});

export function useToast() {
    return useContext(Context);
}

const Container = styled.div`
    position: fixed;
    z-index: 1;
    top: 1rem;
    left: 0;
    right: 0;
    padding: 0 2rem;

    @media screen and (min-width: 768px) {
        left: auto;
    }
`;

const ToastContainer = ({id, ttl, onRemove, children}) => {
    useEffect(
        () => {
            if (!ttl) return;
            const timeoutId = setTimeout(() => {
                onRemove({id});
            }, ttl);

            return () => clearTimeout(timeoutId);
        },
        [ttl, id, onRemove],
    );

    return children;
};

const animation = {
    from: {transform: "translate3d(0,-40px,0)", opacity: 0},
    enter: {transform: "translate3d(0,0px,0)", opacity: 1},
    leave: {transform: "translate3d(0,-40px,0)", opacity: 0},
};

export default function ToastProvider({children}) {
    const [items, setToasts] = useState([]);
    const onRemove = useCallback(({id}) => {
        setToasts(arr => {
            const indexToRemove = arr.findIndex(item => {
                return item.id === id;
            });
            const result = [...arr.slice(0, indexToRemove), ...arr.slice(indexToRemove + 1)];
            return result;
        });
    }, []);

    const renderToast = useCallback(
        (toast, {ttl} = {}) => {
            const id = v4();
            const dismiss = () => {
                onRemove({id});
            };
            setToasts(state => [
                {id, toast: cloneElement(toast, {id, onDismiss: dismiss}), ttl},
                ...state,
            ]);

            return {
                dismiss,
            };
        },
        [onRemove],
    );

    const addToast = useCallback(
        ({title, text, tone, ttl}) => {
            return renderToast(<Toast title={title} text={text} tone={tone} />, {ttl});
        },
        [renderToast],
    );

    const value = useMemo(() => ({addToast, renderToast, dismissToast: onRemove}), [
        addToast,
        renderToast,
        onRemove,
    ]);
    const transitions = useTransition(items, item => item.id, animation);

    return (
        <HiddenErrorBoundary boundaryName="ToastProvider">
            <Context.Provider value={value}>
                <Container>
                    <Stack space="small">
                        {transitions.map(({item, props}) => {
                            const {toast, ttl, id} = item;
                            return (
                                <animated.div key={id} style={props}>
                                    <ToastContainer onRemove={onRemove} id={id} ttl={ttl}>
                                        {toast}
                                    </ToastContainer>
                                </animated.div>
                            );
                        })}
                    </Stack>
                </Container>
                {children}
            </Context.Provider>
        </HiddenErrorBoundary>
    );
}
