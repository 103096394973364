import {useEffect} from "react";
import useAuth from "@unibuddy/machop/Auth/hooks/useAuth";
import {useApolloClient, useMutation} from "react-apollo";
import gql from "graphql-tag";
import {get} from "lodash";
import {useParams} from "react-router";
import {useVirtualEvent} from "../../core/components/VirtualEventProvider/VirtualEventProvider";
import {AttendeeAnalyticsMutation, AttendeeAnalyticsMutationVariables} from "../../types";

export const attendeeAnalyticsMutation = gql`
    mutation attendeeAnalytics(
        $virtualEventId: ObjectId!
        $userId: ObjectId!
        $accountRole: String!
    ) {
        addAttendees(
            attendeeData: {
                virtualEventId: $virtualEventId
                userId: $userId
                accountRole: $accountRole
            }
        ) {
            id
            virtualEventId
            userId
            accountRole
        }
    }
`;

export const attendeeAnalyticsFragment = gql`
    fragment AttendeeAnalytics on AttendeeAnalytics {
        accountRole
        id
        userId
        virtualEventId
    }
`;

export default async function useAttendeeAnalytics() {
    const {virtualEventId} = useParams<{virtualEventId: string}>();
    const {isLoggedIn, authState} = useAuth();
    const {isVirtualEventLive, hasVirtualEventEnded} = useVirtualEvent();
    const client = useApolloClient();

    const [attendeeAnalytics] = useMutation<
        AttendeeAnalyticsMutation,
        AttendeeAnalyticsMutationVariables
    >(attendeeAnalyticsMutation);

    const user = get(authState, "me.anyUser", "");

    useEffect(() => {
        if (
            !isVirtualEventLive ||
            hasVirtualEventEnded ||
            !virtualEventId ||
            !isLoggedIn ||
            !user?.id
        )
            return;

        async function updateAnalytics() {
            const existingAttendee = client.readFragment({
                id: `AttendeeAnalytics:${user.id}`,
                fragment: attendeeAnalyticsFragment,
            });

            if (existingAttendee) return;

            await attendeeAnalytics({
                variables: {
                    virtualEventId,
                    userId: user.id,
                    accountRole: user.accountRole,
                },
            });
        }
        updateAnalytics();
    }, [
        attendeeAnalytics,
        user,
        isLoggedIn,
        virtualEventId,
        client,
        isVirtualEventLive,
        hasVirtualEventEnded,
    ]);
}
