import React, {memo} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {Box, Inline, TextBlock, CloseIcon, Button} from "@unibuddy/patron";
import {ClientEvent, useSocketListener} from "@unibuddy/sockets";
import WarningIcon from "../Icons/WarningIcon";
import GoToLinkIcon from "../Icons/GoToLink";
import {useIsBigScreen} from "../Utils/hooks/useIsBigScreen";
import {useAlert} from "./AlertProvider";

type VariantInfo = {
    text: {color: string};
    title: {fontWeight: string};
    backgroundBox: {bgColor: string; hoverBgColor: string};
};

type Alert = {showAlert: boolean; message: string; variant: string};

const AnimatedBox = styled(Box)`
    @keyframes slide-in {
        from {
            transform: translateY(-100%);
            opacity: 0.25;
        }
        to {
            transform: translateY(0%);
            opacity: 1;
        }
    }

    animation: slide-in 500ms ease-in-out;
    animation-fill-mode: backwards;
`;

const DismissButton = styled(Button)<{hoverBgColor: string}>`
    &:hover:not([disabled]) {
        background-color: ${({hoverBgColor}) => hoverBgColor};
    }
`;

const variants: Record<string, VariantInfo> = {
    ERROR: {
        text: {color: "#A50000"},
        title: {fontWeight: "700"},
        backgroundBox: {bgColor: "#FFC1C1", hoverBgColor: "#E6A8A8"},
    },
    WARN: {
        text: {color: "#404446"},
        title: {fontWeight: "700"},
        backgroundBox: {bgColor: "#FFDFC1", hoverBgColor: "#FFDFC1"},
    },
};

type AlertProps = {
    channelId: string;
    event: ClientEvent;
    link?: any;
    linkText?: string;
};

function Alert({channelId, event, link, linkText}: AlertProps) {
    const {isBigScreen} = useIsBigScreen();
    const {alert, setAlert} = useAlert();

    useSocketListener({
        event,
        channelId,
        onEvent: ({variant, message}: {variant: string; message: string}) => {
            setAlert({variant, message, showAlert: true});
        },
    });

    return alert?.showAlert ? (
        <AnimatedBox
            position="absolute"
            zIndex={9}
            display="flex"
            w="100%"
            bgColor={variants?.[alert.variant]?.backgroundBox?.bgColor}
            padding="small"
        >
            <Box display="flex" flex="1" justifyContent="center" alignItems="center">
                <Inline verticalAlign="center" space="xxsmall">
                    {isBigScreen ? (
                        <WarningIcon
                            display="block"
                            color={variants?.[alert.variant]?.text?.color}
                        />
                    ) : null}
                    <TextBlock color={variants?.[alert.variant]?.text?.color} weight="600">
                        {alert.message}{" "}
                        {link ? (
                            <Link target="_blank" rel="noopener noreferrer" to={link}>
                                {linkText} <GoToLinkIcon display="inline-block" />
                            </Link>
                        ) : null}
                    </TextBlock>
                </Inline>
            </Box>

            <DismissButton
                aria-label="Close Alert Notification"
                size="sm"
                clear
                iconOnly
                hoverBgColor={variants?.[alert.variant]?.backgroundBox?.hoverBgColor}
                onClick={() => setAlert({variant: "", message: "", showAlert: false})}
            >
                <CloseIcon
                    color={variants?.[alert.variant]?.text?.color}
                    height={20}
                    width={20}
                    aria-hidden
                />
            </DismissButton>
        </AnimatedBox>
    ) : null;
}

export default memo(Alert);
