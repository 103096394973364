import React from "react";
import styled from "styled-components";
import {Inline, TextBlock, TextLine, Logo} from "@unibuddy/patron";

const UBLogo = styled(Logo)`
    svg {
        display: block;
    }
`;

const PoweredBy = () => (
    <Inline space="small" verticalAlign="center" wrap="nowrap" align="center">
        <TextBlock size="small" nowrap>
            Powered By
        </TextBlock>
        <UBLogo width={26} displayText={false} className="" />
        <div style={{letterSpacing: "2px"}}>
            <TextBlock size="small" nowrap>
                <TextLine weight="700">UNI</TextLine>BUDDY
            </TextBlock>
        </div>
    </Inline>
);

export default PoweredBy;
