import "babel-polyfill";
import "whatwg-fetch";
import {ApolloClient} from "apollo-client";
import {ApolloLink} from "apollo-link";
import createHistory from "history/createBrowserHistory";
import localForage from "localforage";
import get from "lodash/get";
import React, {useState, useEffect} from "react";
import {ApolloProvider} from "react-apollo";
import {render} from "react-dom";
import {Provider} from "react-redux";
import {persistStore} from "redux-persist";
import {Router, Switch, Route} from "react-router-dom";

// eslint-disable-next-line import/extensions
import "reset-css";
import {getConsents} from "@unibuddy/machop/CookieManager/CookieManager";
import Messenger from "@unibuddy/machop/Chat/messenger";
import defaultLink from "@unibuddy/machop/Core/defaultLink";
import AuthProvider from "@unibuddy/machop/Auth/AuthProvider/AuthProvider";

import {initMixpanel} from "./Utils/mixpanel";
import configureStore from "./core/store/configureStore";
import App from "./App";
import VirtualEventApp from "./VirtualEventApp";
import ErrorPage from "./Landing/containers/ErrorPage/ErrorPage";
import createCustomApolloCache from "./core/store/createCustomApolloCache";
import "./styles/main.pcss";
import {PREFIX} from "./VirtualEventRoutes";

const store = configureStore();

const liveEventLink = ApolloLink.from([defaultLink(store, process.env.GATEWAY_URL)]);
const virtualEventLink = ApolloLink.from([defaultLink(store, process.env.GATEWAY_URL)]);

const liveEventClient = new ApolloClient({
    link: liveEventLink,
    cache: createCustomApolloCache(),
    resolvers: {
        EventPagesDisplayedOn: {
            _deleted: (eventPagesDisplayedOn) => Boolean(eventPagesDisplayedOn._deleted),
        },
    },
    name: "virtual-events-webinar",
    version: "unknown",
});

// Only connect to apollo dev tools if we're on dev
const virtualEventClient = new ApolloClient({
    link: virtualEventLink,
    cache: createCustomApolloCache(),
    connectToDevTools: !process.env.ENV_NAME,
    resolvers: {
        EventPagesDisplayedOn: {
            _deleted: (eventPagesDisplayedOn) => Boolean(eventPagesDisplayedOn._deleted),
        },
    },
    name: "virtual-events-microsite",
    version: "unknown",
});

const history = createHistory();

const AppProvider = () => {
    const [rehydrated, setRehydrated] = useState(false);
    const [cookiesBlocked, setCookiesBlocked] = useState(false);
    const [analyticsAdaptors, setAnalyticsAdaptors] = useState([]);

    useEffect(() => {
        const adaptors = [];
        const preferences = getConsents();
        adaptors.push(initMixpanel(preferences));
        setAnalyticsAdaptors(...adaptors);
    }, []);

    useEffect(() => {
        localForage
            .ready()
            .then(() => {
                persistStore(store, {whitelist: ["authState"], storage: localForage}, async () => {
                    const token = get(store.getState(), "authState.token", false);
                    setRehydrated(true);
                    const messengerState = get(store.getState(), "messengerState");
                    if (token) await Messenger.connect(token, messengerState, store.dispatch);
                });
            })
            .catch(() => {
                setCookiesBlocked(true);
                setRehydrated(true);
            });
    }, []);

    if (!rehydrated) {
        return null;
    }

    if (cookiesBlocked) {
        return <ErrorPage message="You must enable cookies to use this service." />;
    }

    return (
        <Router history={history}>
            <Switch>
                <Route
                    path={[
                        `/:universitySlug/${PREFIX}/:virtualEventId`,
                        `/:universitySlug/${PREFIX}`,
                    ]}
                >
                    <ApolloProvider client={virtualEventClient}>
                        <Provider store={store}>
                            <AuthProvider>
                                <VirtualEventApp
                                    store={store}
                                    analyticsAdaptors={analyticsAdaptors}
                                />
                            </AuthProvider>
                        </Provider>
                    </ApolloProvider>
                </Route>
                <Route>
                    <ApolloProvider client={liveEventClient}>
                        <Provider store={store}>
                            <AuthProvider>
                                <App store={store} analyticsAdaptors={analyticsAdaptors} />
                            </AuthProvider>
                        </Provider>
                    </ApolloProvider>
                </Route>
            </Switch>
        </Router>
    );
};

if (module.hot) module.hot.accept();

render(<AppProvider />, document.getElementById("container"));
