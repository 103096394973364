const defaultColor = "687787";

export const BAD_CONNECTION_COLOR = "#FFDFC1";

// These are the metrics required to format Nunito text correctly.
const nunitoMetrics = {
    capHeight: 705,
    ascent: 1011,
    descent: -353,
    lineGap: 0,
    unitsPerEm: 1000,
};

export const virtualEventsTheme = (color?: string | null) => {
    return {
        colors: {
            faintGrey: "#F3F5F5",
            darkGrey: "#404446",
            lightGrey: "#D0D9D9",
            strongGrey: "#949D9F",
            lightBlue: "#EDF3F9",
            darkBlue: "#127DE9",
            lightRed: "#FFC1C1",
            red: "#C94343",
            darkRed: "#A50000",
            faintBlue: "#DFEDF7",
            strongBlue: "#0171E0",
            universityColor: `#${color || defaultColor}`,
            activeGreen: "#6fcf97",
        },
        shadows: {
            activeButton: "X0 Y0 B0 S2 #DFEDF6",
        },
        virtualEvents: {
            layout: {
                header: {
                    height: "66px",
                },
                footer: {
                    height: "48px",
                },
            },
        },
        TextBlock: {
            fontFamily: "Nunito",
            fontMetrics: nunitoMetrics,
            sizes: {
                xxsmall: {
                    fontSize: 12,
                    lineGap: 8,
                },
                xsmall: {
                    fontSize: 13,
                    lineGap: 9,
                },
                small: {
                    fontSize: 14,
                    lineGap: 10,
                },
                standard: {
                    fontSize: 16,
                    lineGap: 12,
                },
                medium: {
                    fontSize: 16,
                    lineGap: 12,
                },
                large: {
                    fontSize: 18,
                    lineGap: 20,
                },
                xlarge: {
                    fontSize: 22,
                    lineGap: 26,
                },
                xxlarge: {
                    fontSize: 26,
                    lineGap: 30,
                },
            },
        },
        Heading: {
            fontFamily: "Nunito",
            fontMetrics: nunitoMetrics,
            sizes: {
                xxsmall: {
                    fontSize: 18,
                    lineGap: 20,
                },
                xsmall: {
                    fontSize: 20,
                    lineGap: 21,
                },
                small: {
                    fontSize: 24,
                    lineGap: 25,
                },
                standard: {
                    fontSize: 26,
                    lineGap: 24,
                },
                medium: {
                    fontSize: 26,
                    lineGap: 24,
                },
                large: {
                    fontSize: 32,
                    lineGap: 28,
                },
                xlarge: {
                    fontSize: 48,
                    lineGap: 36,
                },
                xxlarge: {
                    fontSize: 64,
                    lineGap: 52,
                },
            },
        },
        Button: {
            colors: {
                agendaButton: {
                    color: "#404546",
                    bgColor: "#fff",
                    borderColor: "#D0D9D9",
                },
                activeAgendaButton: {
                    color: "#404546",
                    bgColor: "#fff",
                    borderColor: "#0171E0",
                },
                filterButton: {
                    borderColor: "#0171E0",
                    bgColor: "#0171E0",
                    color: "#fff",
                    clearColor: "#0171E0",
                },
                agendaFilterMobile: {
                    borderColor: "#f2f5f7",
                    focusedBorderColor: "#0171E0",
                    color: "#3e3e48",
                    bgColor: "#f2f5f7",
                },
                goodWifiButton: {
                    borderColor: "#FFFFFF",
                    focusedBorderColor: "#000000",
                    bgColor: "#FFFFFF",
                },
                badWifiButton: {
                    borderColor: BAD_CONNECTION_COLOR,
                    focusedBorderColor: "#000000",
                    bgColor: BAD_CONNECTION_COLOR,
                },
            },
            heights: {
                xl: "50px",
            },
        },
        TextInput: {
            height: "50px",
            borderRadius: "4px",
            fontSize: "16px",
            color: "#3e3e48",
            backgroundColor: "#f2f5f7",
            placeholderColor: "#a1aeb7",
            variants: {
                disabled: {
                    backgroundColor: "#c2d1d9",
                },
                focus: {
                    borderColor: "#0171E0",
                },
                error: {
                    borderColor: "#a50000",
                },
            },
        },
        iconOnly: {
            heights: {
                inherit: "inline",
            },
        },
    };
};
