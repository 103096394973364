import {useEffect} from "react";
import {useQuery} from "react-apollo";
import gql from "graphql-tag";
import {useParams} from "react-router";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {UniversityQueryQuery, UniversityQueryQueryVariables, UniversityField} from "../../types";

export const universityQuery = gql`
    query universityQuery($universitySlug: String!) {
        university(slug: $universitySlug) {
            id
            name
            logo
            colour
        }
    }
`;

export default function useUniversity(): {
    university?: UniversityField | null;
    loading: boolean;
} {
    const {universitySlug} = useParams<{universitySlug: string}>();
    const {reportError} = useErrorReporting();

    const {data, loading, error} = useQuery<UniversityQueryQuery, UniversityQueryQueryVariables>(
        universityQuery,
        {
            variables: {
                universitySlug,
            },
        },
    );

    useEffect(() => {
        if (error) reportError(error);
    }, [error, reportError]);

    return {university: data?.university, loading};
}
