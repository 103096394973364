import qs from "qs";

/**
 * Takes a url and param name and returns the param value
 * e.g. if url=unibuddy.co/embed/your-university/colour/123456/?slug=hello&cake=blackforest
 * and name=cake then 'blackforest' will be returned.
 * This method should be replaced with https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams#Browser_compatibility
 * once the browser compatibility improves
 * @param name
 * @param url
 * @returns parameter value
 */
function getParameterByName(url, name) {
    name = name.replace(/[[\]]/g, "\\$&");
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/**
 * Takes a {name:value,name:value,} object and returns a query string containing the params and values
 * e.g. If {dessert:cake, type:blackforest} is passed, it returns "?dessert=cake&type=blackforest"
 * @param params
 * @returns query string containing the parameters and its values
 */
function makeQueryString(params) {
    return `?${qs.stringify(params)}`;
}

/**
 * Takes a url and a list of parameter names, returns a string of params (in the order of
 * of the list of names) - string can be appended to another URL
 * e.g. If url is www.myuni.com/page/?param1=value1&param2=value2&param3=value3
 * and param_names is [param3, param1] then the param string return expected is param3=value3&param1=value1
 * @param url
 * @param paramNames
 */
function getParamsString(url, paramNames) {
    const paramsObject = {};
    paramNames.forEach(paramName => {
        const paramValue = getParameterByName(url, paramName);
        if (paramValue) paramsObject[paramName] = paramValue;
    });
    return qs.stringify(paramsObject);
}

/**
 * Gets param string with all source tracking params
 * Source tracking params are: ub_medium, ub_source, ub_campaign, ub_content
 * @param url
 * @returns string with source string params
 */
function getSourceTrackingParamsString(url) {
    const sourceTrackingParams = ["ub_medium", "ub_source", "ub_campaign", "ub_content"];
    return getParamsString(url, sourceTrackingParams);
}

/**
 * Injects source tracking params into the given url
 * Source tracking params are: ub_medium, ub_source, ub_campaign, ub_content
 * @param url
 * @returns url with source string params included
 */
function injectSourceTrackingParamsInUrl(url, params) {
    /**
     * If url already contains source tracking params, don't do anything
     */
    if (!getSourceTrackingParamsString(url) && params) {
        let sourceTrackingParamString = makeQueryString({
            ub_medium: params.ub_medium,
            ub_source: params.ub_source,
            ub_campaign: params.ub_campaign,
            ub_content: params.ub_content,
        });

        /**
         * strip out the ? at the front because the url might already have query params in which case,
         * we only need to append the source tracking params with a & to the url.
         */
        if (sourceTrackingParamString.startsWith("?")) {
            sourceTrackingParamString = sourceTrackingParamString.substr(1);
        }

        if (url.indexOf("?") !== -1) {
            url = `${url}&${sourceTrackingParamString}`;
        } else if (url.indexOf("?") === -1) {
            url = `${url}?${sourceTrackingParamString}`;
        }
    }

    return url;
}

function getHostName(url) {
    if (!url) return null;
    url = url.toLowerCase();
    let domain;
    //find & remove protocol (http, ftp, etc.)
    if (url.indexOf("://") > -1) domain = url.split("/")[2];
    else domain = url.split("/")[0];
    //find & remove www[0-9], port numbers
    const match = domain.match(/(www[0-9]?\.)?(.[^/:]+)/i);
    if (match != null && match.length > 2 && typeof match[2] === "string" && match[2].length > 0)
        domain = match[2];
    //find & remove url params
    domain = domain.split("?")[0];
    return domain;
}

function addQueryStringParams(url, params) {
    let newUrl = "";
    const stringifiedQs = qs.stringify(params);

    if (url.indexOf("?") !== -1) {
        newUrl = `${url}&${stringifiedQs}`;
    } else if (url.indexOf("?") === -1) {
        newUrl = `${url}?${stringifiedQs}`;
    }

    return newUrl;
}

export {
    getParameterByName,
    getParamsString,
    getSourceTrackingParamsString,
    makeQueryString,
    injectSourceTrackingParamsInUrl,
    addQueryStringParams,
    getHostName,
};
