import {ONE_TRUST_COOKIE_LIST_ID_MAP} from "@unibuddy/machop/Utils/Constants";

export const getConsents = () => {
    const acceptedConsents = [];
    const activeGroups = window.OnetrustActiveGroups ? window.OnetrustActiveGroups : "";

    for (const cookieId in ONE_TRUST_COOKIE_LIST_ID_MAP) {
        if (activeGroups.includes(cookieId)) {
            acceptedConsents.push(ONE_TRUST_COOKIE_LIST_ID_MAP[cookieId]);
        }
    }
    return acceptedConsents;
};
