import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import {initReactI18next} from "react-i18next";
import {DEFAULT_LOCALE} from "./constants";

const getHttpBackend = app => {
    return {
        crossDomain: true,
        loadPath: `${TRANSLATIONS_URL}/namespaces/${app}/{{lng}}/{{ns}}.json`,
        parse: data => {
            return JSON.parse(data);
        },
    };
};

const initI18n = (app, lng) => {
    const httpBackend = getHttpBackend(app);

    i18n.use(HttpApi)
        .use(initReactI18next)
        .init({
            lng: lng || DEFAULT_LOCALE,
            load: "currentOnly",
            fallbackLng: "en-GB",
            ns: "common",
            defaultNS: "common",
            interpolation: {
                escapeValue: false,
            },
            backend: httpBackend,
            react: {
                bindI18n: "languageChanged",
            },
            debug: process.env.NODE_ENV === "development",
        });
};

export default initI18n;
