import React, {ReactNode} from "react";
import {Redirect, useLocation, useParams, useRouteMatch} from "react-router";
import {connect} from "react-redux";
import compose from "lodash/flowRight";
import useAuth from "@unibuddy/machop/Auth/hooks/useAuth";
import {clearAuth} from "@unibuddy/machop/Auth/actions/authActions";
import VerifiedRoute from "../VerifiedRoute/VerifiedRoute";

interface IProtectedRouteProps {
    path: string;
    children: ReactNode;
    dispatch: any;
    isValidUser: (authState: any) => boolean;
}

export function ProtectedRoute({path, dispatch, children, isValidUser}: IProtectedRouteProps) {
    const {isLoggedIn, authState} = useAuth();
    const match = useRouteMatch();
    const {universitySlug, virtualEventId} = useParams<{
        universitySlug: string;
        virtualEventId: string;
    }>();
    const location = useLocation();

    if (!isLoggedIn) {
        return (
            <Redirect
                exact
                to={{
                    pathname: `${match.url}/auth/sign-up-step-one`,
                    search: `?redirectPath=${location.pathname}`,
                }}
            />
        );
    }

    if (!isValidUser(authState)) {
        // Log the user out
        dispatch(clearAuth());
        return (
            <Redirect
                exact
                to={{
                    pathname: `/${universitySlug}/virtual/${virtualEventId}/403`,
                    search: `?redirectPath=${location.pathname}`,
                }}
            />
        );
    }

    return <VerifiedRoute path={path}>{children}</VerifiedRoute>;
}

export default compose(connect())(ProtectedRoute);
