import get from "lodash/get";
import useUniversityFeature from "@unibuddy/machop/Feature/useUniversityFeature";
import {useIsBigScreen} from "../Utils/hooks/useIsBigScreen";

declare global {
    interface Window {
        Intercom(update: string, params?: any): void;
    }
}

interface IntercomWidgetMe {
    anyUser: {
        id: string;
        accountRole: string;
        email: string;
        firstName: string;
    };
}

const appId = process.env.NODE_ENV === "test" ? "AppId" : process.env.INTERCOM_APP_ID;

const initIntercomWidget = (me: IntercomWidgetMe | undefined) => {
    if (!me) return;
    const user = me.anyUser;

    window.Intercom("boot", {
        app_id: appId,
        user_id: user.id,
        email: user.email,
        name: `${user.firstName} ${get(user, "lastName", "")}`,
        role: user.accountRole,
    });
};

const removeIntercomWidget = () => {
    if (window.Intercom) {
        window.Intercom("shutdown");
    }
};

interface SupportWidgetProps {
    me: IntercomWidgetMe | undefined; //TODO update when we type AuthState
    universitySlug: string;
}

const SupportWidget = ({me, universitySlug}: SupportWidgetProps) => {
    const {loading, isFeatureEnabled} = useUniversityFeature({
        universitySlug,
        feature: "intercomWidget",
    });
    const {isBigScreen} = useIsBigScreen();
    const isValidUserRole = me?.anyUser && me.anyUser.accountRole === "university";
    const shouldShowIntercomWidget = isBigScreen && isValidUserRole && window.Intercom && !!appId;

    if (!loading) {
        if (isFeatureEnabled && shouldShowIntercomWidget) {
            initIntercomWidget(me);
        } else {
            removeIntercomWidget();
        }
    }
    return null;
};

export default SupportWidget;
