import React from "react";
import {Redirect, Route, useLocation, useParams, useRouteMatch} from "react-router";
import {VisuallyHidden} from "@unibuddy/patron";
import useAuth from "@unibuddy/machop/Auth/hooks/useAuth";
import Auth from "@unibuddy/machop/Auth/auth";
import useVerifiedUser from "../../../Auth/components/VerifyEmail/useVerificationRequired";

type VerifiedRouteProps = React.PropsWithChildren<{path: string}>;

export default function VerifiedRoute({children, path}: VerifiedRouteProps) {
    const {isLoggedIn, authState} = useAuth();
    const {isVerificationRequired, loading} = useVerifiedUser();
    const location = useLocation();
    const match = useRouteMatch();
    const {universitySlug} = useParams<{universitySlug: string}>();

    if (loading) return <VisuallyHidden>Loading...</VisuallyHidden>;

    if (!isLoggedIn) return <Route path={path}>{children}</Route>;

    if (isVerificationRequired) {
        return (
            <Redirect
                to={{
                    pathname: `${match.url}/auth/verify`,
                    search: `?redirectPath=${location.pathname}`,
                }}
            />
        );
    }

    if (
        Auth.isApplicant(authState.me) &&
        !Auth.isApplicantUniversitySetup(authState.me, universitySlug)
    ) {
        return (
            <Redirect
                to={{
                    pathname: `${match.url}/auth/sign-up-step-two`,
                    search: `?redirectPath=${location.pathname}`,
                }}
            />
        );
    }

    return <Route path={path}>{children}</Route>;
}
