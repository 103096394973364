import mixpanel from "mixpanel-browser";
import mixpanelAdaptor from "@unibuddy/machop/AnalyticsNew/AnalyticsProvider/Adaptors/mixpanelAdaptor";
import {COOKIE_CATEGORIES, MIXPANEL} from "@unibuddy/machop/Utils/Constants";

/**
 * Because mixpanel is a singleton, its important that we only invoke it once
 * and this should be at the top of the app.
 */
mixpanel.init(process.env.MIXPANEL_ID, {
    api_host: process.env.MIXPANEL_PROXY_HOST,
});
/**
 * https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelopt_in_tracking
 * We opt the user out of being tracked until they give
 * their express consent to opt in
 */
mixpanel.opt_out_tracking();

export const initMixpanel = (preferences) => {
    const adaptors = [];
    let mixpanelInit = false;
    preferences.forEach((preference) => {
        if (preference) {
            const key = preference.trim().toLowerCase();
            if (
                !mixpanelInit &&
                COOKIE_CATEGORIES.has(key) &&
                COOKIE_CATEGORIES.get(key).includes(MIXPANEL)
            ) {
                /**
                 * https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelopt_in_tracking
                 * We use this method to confirm that a user is opting into being tracked.
                 */
                mixpanel.opt_in_tracking();
                adaptors.push(mixpanelAdaptor);
                mixpanelInit = true;
            }
        }
    });
    return adaptors;
};

export const analyticsEnabled = () => {
    return mixpanel.has_opted_in_tracking();
};
