import React, {
    useState,
    useContext,
    createContext,
    ReactNode,
    useMemo,
    Dispatch,
    SetStateAction,
} from "react";

type AlertContextProps = {
    alert: {showAlert: boolean; message: string; variant: string};
    setAlert: Dispatch<SetStateAction<{showAlert: boolean; message: string; variant: string}>>;
};

export const AlertContext = createContext<AlertContextProps>({} as AlertContextProps);

export function useAlert() {
    return useContext(AlertContext);
}

/**
 * AlertProvider wraps the VideoV2 components to give access to a alert
 * property and setVideoError handler. The alert state can be deconstructed
 * to have the following properties:
 * - showAlert: A boolean whether or not an alert should be shown
 * - message: The message to show the user
 * - variant: The kind of alert we need to show the user e.g. Error, Success etc.
 */

export default function AlertProvider({children}: {children: ReactNode}) {
    const [alert, setAlert] = useState<{
        showAlert: boolean;
        message: string;
        variant: string;
    }>({showAlert: false, message: "", variant: ""});

    const value = useMemo(() => {
        return {alert, setAlert};
    }, [alert, setAlert]);

    return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>;
}
