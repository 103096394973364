import isArray from "lodash/isArray";
import mergeWith from "lodash/mergeWith";
import * as actions from "../actions/authActions";

const initialState = {
    token: false,
    me: false,
    userTrackingId: null,
};

function customiser(objValue, srcValue) {
    // if it's an array, just replace with the new value
    // by default lodash does... something peculiar where it tries to match up the
    // elements of the new array with the existing array, leading to situations like:
    // merge(["email", "sms"], ["sms"]) -> ["sms", "sms"]
    if (isArray(objValue)) {
        return srcValue;
    }
    // tell lodash to use default merge functionality
    // (see: https://lodash.com/docs/4.17.4#mergeWith )
    return undefined;
}

function authReducer(state = initialState, action) {
    let mergedMe;
    switch (action.type) {
        case actions.SET_AUTH_TOKEN:
            return {
                ...state,
                token: action.token,
            };
        case actions.SET_USER_TRACKING_ID:
            return {
                ...state,
                userTrackingId: action.userTrackingId,
            };
        case actions.MERGE_AUTH_ME:
            mergedMe = state.me ? mergeWith({}, state.me, action.me, customiser) : action.me;
            return {...state, me: mergedMe};
        case actions.REPLACE_AUTH_ME:
            return {...state, me: action.me};
        case actions.CLEAR_AUTH:
            return {
                ...state,
                token: false,
                me: false,
                initialMe: false,
                initialUniToken: false,
            };
        case actions.SET_INITIAL_UNI_AUTH_DATA:
            return {
                ...state,
                initialMe: action.me,
                initialUniToken: action.token,
            };
        default:
            return state;
    }
}

export default authReducer;
