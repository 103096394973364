import React from "react";
import styled from "styled-components";

const Message = styled.div`
    position: relative;
    min-height: 10rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ErrorPage = ({message = "Sorry, something went wrong, please try again."}) => {
    return <Message>{message}</Message>;
};

export default ErrorPage;
