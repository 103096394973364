import gql from "graphql-tag";

export default gql`
    fragment StaffFull on UserField {
        id
        firstName
        university {
            id
            name
            slug
            defaultProspectWelcomeMessage
            defaultProspectWelcomeMessageStaff
        }
        profilePhoto
        stageOneComplete
        stageTwoComplete
        staffFields {
            role
            department
            bio
        }
        prospectWelcomeMessage
        customAttributes(active: true) {
            value
            attribute {
                id
                name
                type
            }
            ... on SingleChoiceValueField {
                option {
                    id
                    value
                }
            }
        }
    }
`;
