import React, {
    useState,
    createContext,
    useContext,
    useCallback,
    useMemo,
    ReactNode,
    SetStateAction,
    Dispatch,
} from "react";
import {useParams} from "react-router";
import useAuth from "@unibuddy/machop/Auth/hooks/useAuth";
import SupportWidget from "../../../SupportWidget/SupportWidget";

export type VirtualEventDrawerContextType = {
    isOpen: boolean;
    toggleDrawerOpen: Dispatch<SetStateAction<boolean>>;
};

const VirtualEventDrawerContext = createContext<VirtualEventDrawerContextType>(
    {} as VirtualEventDrawerContextType,
);

export function useVirtualEventDrawerContext() {
    return useContext(VirtualEventDrawerContext);
}

export default function VirtualEventLayoutProvider({children}: {children?: ReactNode}) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const {universitySlug} = useParams<{universitySlug: string}>();
    const auth = useAuth();

    const toggleDrawerOpen = useCallback(() => {
        setIsOpen((state) => !state);
    }, [setIsOpen]);

    const value = useMemo(() => ({isOpen, toggleDrawerOpen}), [isOpen, toggleDrawerOpen]);

    return (
        <VirtualEventDrawerContext.Provider value={value}>
            <SupportWidget me={auth.authState.me} universitySlug={universitySlug} />
            {children}
        </VirtualEventDrawerContext.Provider>
    );
}
