import React, {useMemo} from "react";
import useAuth from "@unibuddy/machop/Auth/hooks/useAuth";

import {useVirtualEvent} from "../../core/components/VirtualEventProvider/VirtualEventProvider";
import {recordingFailedEvent} from "../../Sockets/sockets";
import Alert from "../Alert";
import {useLiveEvent} from "../../core/components/LiveEventProvider/LiveEventProvider";

export default function RecordingFailedAlert() {
    const {user, isLoggedIn} = useAuth();
    const virtualEventMatch = useVirtualEvent();
    const liveEventMatch = useLiveEvent();

    const channelId = useMemo(() => {
        if (virtualEventMatch?.virtualEvent) {
            return virtualEventMatch.virtualEvent.id;
        }
        if (liveEventMatch?.liveEvent) {
            const liveStream = liveEventMatch.liveEvent?.liveFeeds?.find(
                (feed) => feed?.videoEnabled,
            );
            return liveStream?.id;
        }
    }, [liveEventMatch.liveEvent, virtualEventMatch.virtualEvent]);

    return channelId && isLoggedIn && user?.accountRole === "university" ? (
        <Alert
            channelId={channelId}
            event={recordingFailedEvent}
            linkText="Go to Admin Dashboard"
            link={`${process.env.ABRA_URL}/events/microsite/admin`}
        />
    ) : null;
}
