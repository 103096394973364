import React, {
    createContext,
    useContext,
    useMemo,
    PropsWithChildren,
    useState,
    useEffect,
} from "react";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {useParams} from "react-router";
import moment from "moment";
import {useErrorReporting} from "@unibuddy/error-reporting";

import {
    VirtualEvent,
    UniversityField,
    BaseVirtualEventQuery,
    BaseVirtualEventQueryVariables,
} from "../../../types";

export const baseVirtualEvent = gql`
    query baseVirtualEvent($virtualEventId: ObjectId!) {
        virtualEvent(id: $virtualEventId) {
            id
            title
            description
            start
            end
            imageUrl
        }
    }
`;

const HOUR_IN_MS = 60 * 60 * 1000;
const DAY_IN_MS = 24 * HOUR_IN_MS;
/**
 * Web browsers cannot store timeout > 24 days approx. it would
 * result in the timeout being called immediately.
 * @url https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#maximum_delay_value
 */
const MAX_TIMEOUT_PROCESSED_IN_MS = 24 * DAY_IN_MS;

type VirtualEventProvider = PropsWithChildren<{
    university?: UniversityField | null;
}>;

export type VirtualEventContextType = {
    university?: UniversityField | null;
    now: string;
    virtualEvent?: Omit<VirtualEvent, "university"> | null;
    isVirtualEventLive: boolean;
    hasVirtualEventEnded: boolean;
    virtualEventLoading: boolean;
    virtualEventHasError: boolean;
};

const VirtualEventContext = createContext<VirtualEventContextType>({
    university: null,
    now: "",
    virtualEvent: null,
    isVirtualEventLive: false,
    hasVirtualEventEnded: false,
    virtualEventLoading: false,
    virtualEventHasError: false,
});

export const useVirtualEvent = () => {
    return useContext(VirtualEventContext);
};

/**
 * Provides a simple way to access the virtual event and university
 * data across the app. To consume the data use the useVirtualEvent hook.
 *
 * @export
 * @param {*} {university, virtual event, children}
 * @returns
 */
export default function VirtualEventProvider({university, children}: VirtualEventProvider) {
    const [isVirtualEventLive, setIsVirtualEventLive] = useState<boolean>(false);
    const [hasVirtualEventEnded, setHasVirtualEventEnded] = useState<boolean>(false);
    const {virtualEventId} = useParams<{virtualEventId: string}>();
    const {reportError} = useErrorReporting();

    const {data, loading, error} = useQuery<BaseVirtualEventQuery, BaseVirtualEventQueryVariables>(
        baseVirtualEvent,
        {
            variables: {virtualEventId},
            skip: !virtualEventId,
        },
    );

    useEffect(() => {
        if (error) reportError(error);
    }, [error, reportError]);

    useEffect(() => {
        if (data?.virtualEvent) {
            const now = moment();
            const start = moment(data?.virtualEvent?.start);
            const startDiff = Math.max(start.diff(now), 0);
            if (startDiff > MAX_TIMEOUT_PROCESSED_IN_MS) {
                return;
            }
            const timeout = setTimeout(() => {
                setIsVirtualEventLive(true);
            }, startDiff);

            return () => clearTimeout(timeout);
        }
    }, [data?.virtualEvent, setIsVirtualEventLive]);

    useEffect(() => {
        if (data?.virtualEvent) {
            const now = moment();
            const end = moment(data?.virtualEvent?.end);
            const endDiff = Math.max(end.diff(now), 0);
            if (endDiff > MAX_TIMEOUT_PROCESSED_IN_MS) {
                return;
            }
            const timeout = setTimeout(() => {
                setHasVirtualEventEnded(true);
            }, endDiff);

            return () => clearTimeout(timeout);
        }
    }, [data?.virtualEvent, setHasVirtualEventEnded]);

    const value: VirtualEventContextType = useMemo(
        () => ({
            university,
            now: new Date(Date.now()).toISOString(),
            virtualEvent: data?.virtualEvent || null,
            isVirtualEventLive,
            hasVirtualEventEnded,
            virtualEventLoading: loading,
            virtualEventHasError: !!error,
        }),
        [university, data?.virtualEvent, isVirtualEventLive, hasVirtualEventEnded, loading, error],
    );

    return <VirtualEventContext.Provider value={value}>{children}</VirtualEventContext.Provider>;
}
