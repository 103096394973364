import {useEffect, useState} from "react";

export function useIsBigScreen() {
    const [media] = useState(() => window.matchMedia("(min-width: 1024px)"));
    const [isBigScreen, setIsBigScreen] = useState(media.matches);
    useEffect(() => {
        const listener = () => {
            setIsBigScreen(media.matches);
        };
        media.addListener(listener);
        return () => {
            media.removeListener(listener);
        };
    }, [media]);

    return {isBigScreen};
}
