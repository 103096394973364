import React from "react";
import styled from "styled-components";
import Box from "@unibuddy/machop/Experimental/Layout/components/Box/Box";
import Stack from "@unibuddy/machop/Experimental/Layout/components/Stack/Stack";
import {Link, useParams} from "react-router-dom";
import {useQueryParams} from "../Utils/hooks/useQueryParams";

const Wrapper = styled.div`
    display: flex;
    height: 100%;
`;

const Container = styled.div`
    margin: 24px auto;
    max-width: 700px;
    border-radius: 8px;
    text-align: center;

    @media screen and (min-width: 768px) {
        font-size: 18px;
    }
`;

const Title = styled.h1`
    color: var(--deep-sky);
    font-size: 32px;
    font-weight: 700;
`;

const Instruction = styled.div`
    color: var(--stone-grey);
`;

export default function Error403() {
    const {virtualEventId, universitySlug} = useParams<{
        universitySlug: string;
        virtualEventId: string;
    }>();
    const redirectPath = useQueryParams("redirectPath");
    const queryString = redirectPath ? `?redirectPath=${redirectPath}` : "";
    return (
        <Wrapper>
            <Container>
                <Box paddingTop="medium" paddingX="xlarge" paddingBottom="xlarge">
                    <Stack space="xlarge">
                        <Title>Access Denied</Title>
                        <Instruction>
                            Whoops! It looks like you are using the wrong account. Click{" "}
                            <Link
                                to={`/${universitySlug}/virtual/${virtualEventId}/auth/login${queryString}`}
                            >
                                here
                            </Link>{" "}
                            to login with another account.
                        </Instruction>
                    </Stack>
                </Box>
            </Container>
        </Wrapper>
    );
}
